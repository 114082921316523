var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form",
      attrs: {
        title: "高级搜索",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "560px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("ul", [
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("创建时间"),
            ]),
            _c("date-range", {
              model: {
                value: _vm.dateRange,
                callback: function ($$v) {
                  _vm.dateRange = $$v
                },
                expression: "dateRange",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("名称"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入名称", size: "small" },
              model: {
                value: _vm.name,
                callback: function ($$v) {
                  _vm.name = $$v
                },
                expression: "name",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("识别码"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入识别码", size: "small" },
              model: {
                value: _vm.code,
                callback: function ($$v) {
                  _vm.code = $$v
                },
                expression: "code",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("关联标准作业"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入作业名称", size: "small" },
              model: {
                value: _vm.sopName,
                callback: function ($$v) {
                  _vm.sopName = $$v
                },
                expression: "sopName",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("创建人"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入创建人", size: "small" },
              model: {
                value: _vm.createdName,
                callback: function ($$v) {
                  _vm.createdName = $$v
                },
                expression: " createdName",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("备注"),
            ]),
            _c("el-input", {
              attrs: {
                type: "textarea",
                rows: 4,
                placeholder: "请输入备注关键字",
                size: "small",
              },
              model: {
                value: _vm.remark,
                callback: function ($$v) {
                  _vm.remark = $$v
                },
                expression: "remark",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }