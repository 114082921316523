<template>
  <div class="js-common-wrap js-operation-record">
    <div class="js-common-head">
      <label>作业记录</label>
      <div>
        <el-button type="primary" size="small" @click="search" v-if="getTempraryShow">高级搜索</el-button>
      </div>
    </div>
    <div class="js-common-content">
      <el-table class="js-table" :data="tableData" height="100%" empty-text="暂无数据" @row-click="handleRowClick">
        <el-table-column label="缩略图" width="160">
          <template slot-scope="scope">
            <img class="img" :src="scope.row.thumb" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="name" label="作业名称"></el-table-column>
        <el-table-column prop="sopCode" label="识别码"></el-table-column>
        <el-table-column prop="sopName" label="关联标准作业"></el-table-column>
        <el-table-column prop="duration" label="时长"></el-table-column>
        <el-table-column prop="gmtCreated" label="创建时间" width="160"></el-table-column>
        <el-table-column prop="createdName" label="创建用户"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline" @click.stop="edit(scope.row)"></i>
            <i class="el-icon-delete" @click.stop="deleteItem(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <common-pagination v-model="pageOptions" @curPageChange="handleCurPageChange"></common-pagination>
    <ope-re-search v-model="searchDialogVisible" @handleSearch="handleSearch"></ope-re-search>
    <ope-re-edit v-model="editDialogVisible" :currentItem="currentItem" @handleItem="handleEdit"></ope-re-edit>
    <common-delete v-model="deleteDialogVisible"></common-delete>
  </div>
</template>
<script>
import CommonPagination from '@/components/CommonPagination'
import OpeReSearch from '@/components/OpeReSearch'
import OpeReEdit from '@/components/OpeReEdit'
import CommonDelete from '@/components/CommonDelete'
import { mapGetters } from "vuex";


export default {
  name: '',
  components: {
    CommonPagination,
    OpeReSearch,
    OpeReEdit,
    CommonDelete
  },
  data() {
    return {
      tableData: [],
      options: {
        startDate: '',
        endDate: '',
        name: '',
        code: '',
        sopName: '',
        createdName: '',
        remark: ''
      },
      pageOptions:{
        page: 1,
        perPage: 20,
        total: 0
      },
      searchDialogVisible: false,
      editDialogVisible: false,
      deleteDialogVisible: false,
      currentItem: ''
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    ...mapGetters(["getTempraryShow"]),
  },
  methods: {
    getList(){
      this.$axios({
        method: 'get',
        url: 'api/csp/sop/v1/sopRecord/page',
        params:{
          startDate: this.options.startDate,
          endDate: this.options.endDate,
          name: this.options.name,
          code:this.options.code,
          sopName: this.options.sopName,
          createdName: this.options.createdName,
          remark: this.options.remark,
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.tableData = data.data.rows
          this.pageOptions.total = data.data.total
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    // 分页
    handleCurPageChange(){
      this.getList()
    },
    search(){
      this.searchDialogVisible = true
    },
    handleSearch(params){
      this.pageOptions.page = 1
      this.searchDialogVisible = false
      this.options.startDate = params.startDate
      this.options.endDate = params.endDate
      this.options.name = params.name
      this.options.code = params.code
      this.options.sopName = params.sopName
      this.options.createdName = params.createdName
      this.options.remark = params.remark
      this.getList()
    },
    edit(item){
      this.currentItem = this.$Utils.deepCopy(item)
      this.editDialogVisible = true
    },
    handleEdit(params){
      this.$axios({
        method: 'put',
        url: `api/csp/sop/v1/sopRecord/${params.id}`,
        data: {
          data: {
            name: params.name,
            remark: params.remark
          }
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.editDialogVisible = false
          this.$message({
            type: 'success',
            message: '编辑成功！',
            duration: 1000
          })
          this.pageOptions.page = 1
          this.getList()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    deleteItem(item){
      this.currentItem = item
      this.deleteDialogVisible = true
    },
    handleDelete(){
      this.$axios({
        method: 'delete',
        url: `api/csp/sop/v1/sopRecord/${this.currentItem.id}`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.deleteDialogVisible = false
          this.$message({
            type: 'success',
            message: '删除成功！',
            duration: 1000
          })
          this.pageOptions.page = 1
          this.getList()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    handleRowClick(row){
      this.$router.push({
        path: `/standardOperation/operationRecords/details`,
        query:{
          id: row.id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
