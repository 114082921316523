var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-operation-record" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c("label", [_vm._v("作业记录")]),
        _c(
          "div",
          [
            _vm.getTempraryShow
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.search },
                  },
                  [_vm._v("高级搜索")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "js-common-content" },
        [
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "empty-text": "暂无数据",
              },
              on: { "row-click": _vm.handleRowClick },
            },
            [
              _c("el-table-column", {
                attrs: { label: "缩略图", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticClass: "img",
                          attrs: { src: scope.row.thumb, alt: "" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "作业名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "sopCode", label: "识别码" },
              }),
              _c("el-table-column", {
                attrs: { prop: "sopName", label: "关联标准作业" },
              }),
              _c("el-table-column", {
                attrs: { prop: "duration", label: "时长" },
              }),
              _c("el-table-column", {
                attrs: { prop: "gmtCreated", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createdName", label: "创建用户" },
              }),
              _c("el-table-column", {
                attrs: { prop: "remark", label: "备注" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          staticClass: "el-icon-edit-outline",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.edit(scope.row)
                            },
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.deleteItem(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("common-pagination", {
        on: { curPageChange: _vm.handleCurPageChange },
        model: {
          value: _vm.pageOptions,
          callback: function ($$v) {
            _vm.pageOptions = $$v
          },
          expression: "pageOptions",
        },
      }),
      _c("ope-re-search", {
        on: { handleSearch: _vm.handleSearch },
        model: {
          value: _vm.searchDialogVisible,
          callback: function ($$v) {
            _vm.searchDialogVisible = $$v
          },
          expression: "searchDialogVisible",
        },
      }),
      _c("ope-re-edit", {
        attrs: { currentItem: _vm.currentItem },
        on: { handleItem: _vm.handleEdit },
        model: {
          value: _vm.editDialogVisible,
          callback: function ($$v) {
            _vm.editDialogVisible = $$v
          },
          expression: "editDialogVisible",
        },
      }),
      _c("common-delete", {
        model: {
          value: _vm.deleteDialogVisible,
          callback: function ($$v) {
            _vm.deleteDialogVisible = $$v
          },
          expression: "deleteDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }