var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form",
      attrs: {
        title: "编辑",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "560px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("ul", [
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("作业名称"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入作业名称", size: "small" },
              model: {
                value: _vm.params.name,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "name", $$v)
                },
                expression: "params.name",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("备注"),
            ]),
            _c("el-input", {
              attrs: {
                type: "textarea",
                rows: 4,
                placeholder: "请输入备注关键字",
                size: "small",
              },
              model: {
                value: _vm.params.remark,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "remark", $$v)
                },
                expression: "params.remark",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }