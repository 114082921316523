<template>
  <el-dialog class="js-dialog-form" title="高级搜索" :visible.sync="dialogVisible" :close-on-click-modal="false" width="560px">
    <ul>
      <!-- <li class="label">创建时间</li> -->
      <li>
        <div class="label common-tip"><i>*</i>创建时间</div>
        <date-range v-model="dateRange"></date-range>
      </li>
      <!-- <li class="label">名称</li> -->
      <li>
        <div class="label common-tip"><i>*</i>名称</div>
        <el-input v-model="name" placeholder="请输入名称" size="small"></el-input>
      </li>
      <!-- <li class="label">识别码</li> -->
      <li>
        <div class="label common-tip"><i>*</i>识别码</div>
        <el-input v-model="code" placeholder="请输入识别码" size="small"></el-input>
      </li>
      <!-- <li class="label">关联标准作业</li> -->
      <li>
        <div class="label common-tip"><i>*</i>关联标准作业</div>
        <el-input v-model="sopName" placeholder="请输入作业名称" size="small"></el-input>
      </li>
      <!-- <li class="label">创建人</li> -->
      <li>
        <div class="label common-tip"><i>*</i>创建人</div>
        <el-input v-model=" createdName" placeholder="请输入创建人" size="small"></el-input>
      </li>
      <!-- <li class="label">备注</li> -->
      <li>
        <div class="label common-tip"><i>*</i>备注</div>
        <el-input v-model="remark" type="textarea" :rows="4" placeholder="请输入备注关键字" size="small"></el-input>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import DateRange from '@/components/DateRange'
export default {
  name: '',
  props: ['value'],
  components: {
    DateRange
  },
  data() {
    return {
      dateRange: ['',''],
      name: '',
      code: '',
      sopName: '',
      createdName: '',
      remark: ''
    }
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {
  },
  methods: {
    handleCancel(){
      this.dateRange = ['','']
      this.name = ''
      this.code = ''
      this.sopName = ''
      this.createdName = ''
      this.remark = ''
      this.dialogVisible = false
    },
    handleConfirm(){
      let params = {
        startDate: this.dateRange[0],
        endDate: this.dateRange[1],
        name: this.name,
        code: this.code,
        sopName: this.sopName,
        createdName: this.createdName,
        remark: this.remark
      }
      this.$emit('handleSearch',params)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>